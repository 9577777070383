import styled from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.div`
  padding: 70px 20px;
  width: 100vw;
  overflow-x: hidden;
  ${media.greaterThan('medium')`
    width: 100%;
    padding: 70px 50px;
  `};
  table {
    border: none;
    margin-top: 50px;
    display: table;
    tr {
      overflow: hidden;
      position: relative;
      .detail {
      }
      &:hover > .detail {
        display: none;
        ${media.greaterThan('medium')`
          display: flex;
          justify-content: center;
        `}
        left: 0px;
        width: 35%;
        opacity: 1;
        transition: all ease-out 0.5s;
      }
    }
    .order-td {
      display: flex;
      flex-direction: row;
      border-radius: 30px;
      max-width: 100% !important;

      &.proccessing {
        background: #f2f2f2;
      }
      &.error {
        background: #ffe8e8;
      }
      &.concluded {
        background: rgba(0, 193, 54, 0.1);
      }
    }
    .order-title {
      font-weight: 700;
    }
    td {
      display: table-cell;
      vertical-align: middle;
      margin: 10px 0px;
      padding: 10px 10px;
      max-width: 150px;
      font-size: 1.3rem;
      &.reprovado {
        color: #c50101;
        text-transform: capitalize;
      }
      &.aprovado {
        color: #00c136;
        text-transform: capitalize;
      }
      &.pendente {
        color: orange;
        text-transform: capitalize;
      }
    }

    width: 100%;
    text-align: center;
    color: ${({ theme }) => theme.colors.grayDarker};

    thead {
      font-weight: 700;
      font-size: 1.6rem;
      border-radius: 30px;
      overflow: hidden;

      td {
        &:first-child {
          border-top-left-radius: 30px;
          border-bottom-left-radius: 30px;
        }
        &:last-child {
          border-top-right-radius: 30px;
          border-bottom-right-radius: 30px;
        }
        padding: 10px 20px;
      }
      button {
        background-color: transparent;
        color: ${({ theme }) => theme.colors.red};
        font-weight: 700;
      }
      &.points-thead {
        border: none;
        background-color: #f2f2f2;
        color: ${({ theme }) => theme.colors.red};
      }
    }
    svg {
      fill: ${({ theme }) => theme.colors.red};
    }
  }
`;

export const Message = styled.h1``;

export const Wrapper = styled.section`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(133, 133, 133, 0.3);
  margin-top: -10px;
  z-index: 1;
`;

export const DataWrapper = styled.section`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 20px;

  ${media.greaterThan('medium')`
    justify-content: inherit;
    flex-direction: column;
    &.row {
      flex-direction: row;
    }
  `};
`;

export const DataHeader = styled.div`
  margin-top: 30px;
  margin-bottom: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  form {
    display: flex;
    align-items: center;
    width: 340px;
    svg {
      transform: rotate(-90deg);
      fill: ${({ theme }) => theme.colors.grayDarker};
    }
    span {
      margin-right: 10px;
      font-weight: 700;
      font-size: 1.6rem;
      color: ${({ theme }) => theme.colors.grayDarker};
    }
  }
  p {
    font-size: 1.4rem;
  }
`;

export const PageButton = styled.button`
  background: transparent !important;
  border-radius: 25px;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  color: #484848;
  font-weight: 400;
  font-size: 1.6rem;
  text-decoration: none;
  cursor: pointer;
  &.active {
    color: ${({ theme }) => theme.colors.red};
    font-weight: 700;
  }
`;

export const PageArrowButton = styled.button`
  background: transparent !important;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #484848;
  font-size: 2rem;
  cursor: pointer;
`;

export const PageButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 15px 25px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 50px;
`;

export const Detail = styled.div`
  display: none;
  ${media.greaterThan('medium')`
    display: block;
  `}
  opacity: 0;
  position: absolute;
  padding: 10px 20px;
  background-color: #c50101;
  left: 0px;
  width: 0;
  overflow: hidden;
  white-space: nowrap;
  color: white;
  transform: translate(0%, 25%);
  border-radius: 25px;
  margin-left: 300px;
`;

export const TableContainer = styled.div`
  overflow-x: auto;
  max-width: 100vw;
`;
