import AwardsCatalog from '~/screens/AwardsCatalog';
import Calculator from '~/screens/Calculator';
import Dashboard from '~/screens/Dashboard';
import Extract from '~/screens/Extract';
import Faq from '~/screens/Faq';
import Gigaline from '~/screens/Gigaline';
import Gn from '~/screens/Gn';
import Profile from '~/screens/Profile';
import Receita from '~/screens/Receita';
import Regulations from '~/screens/Regulations';
import TablePoints from '~/screens/TablePoints';
import Team from '~/screens/Team';
import Tickets from '~/screens/Tickets/List';
import CreateTickets from '~/screens/Tickets/Create';
import AboutCampaign from '~/screens/AboutCampaign';
import Notifications from '~/screens/Notifications';
import Lad from '~/screens/LAD';
import MediaCampaign from '~/screens/MediaCampaign';
import Campaign from '~/screens/MediaCampaign/Campaign';
import QuizzesMain from '~/screens/Quizzes/Pages/Main';
import QuizzesTraining from '~/screens/Quizzes/Pages/Trainning';

const routes = {
  Dashboard: {
    path: '/dashboard',
    exact: true,
    component: Dashboard,
  },
  AboutCampaign: {
    path: '/sobre-a-campanha',
    exact: true,
    component: AboutCampaign,
  },
  Faq: {
    path: '/faq',
    exact: true,
    component: Faq,
  },
  AwardsCatalog: {
    path: '/catalogo-de-premios',
    exact: true,
    component: AwardsCatalog,
  },
  Gigaline: {
    path: '/gigaline',
    exact: true,
    component: Gigaline,
  },
  GigalineId: {
    path: '/gigaline/:id',
    exact: true,
    component: Gigaline,
  },
  TablePoints: {
    path: '/tabela-de-pontos',
    exact: true,
    component: TablePoints,
  },
  Gn: {
    path: '/bolsao-do-gn',
    exact: true,
    component: Gn,
  },
  Extract: {
    path: '/extrato',
    exact: true,
    component: Extract,
  },
  Receita: {
    path: '/receita',
    exact: true,
    component: Receita,
  },
  Calculator: {
    path: '/calculadora',
    exact: true,
    component: Calculator,
  },
  Regulations: {
    path: '/regulamento',
    exact: true,
    component: Regulations,
  },
  Profile: {
    path: '/meu-perfil',
    exact: true,
    component: Profile,
  },
  Team: {
    path: '/minha-equipe',
    exact: true,
    component: Team,
  },
  Tickets: {
    path: '/ocorrencias',
    exact: true,
    component: Tickets,
  },
  CreateTickets: {
    path: '/criar-ocorrencias',
    exact: true,
    component: CreateTickets,
  },
  Notifications: {
    path: '/notificacoes',
    exact: true,
    component: Notifications,
  },
  Lad: {
    path: '/bolsao-do-lad',
    exact: true,
    component: Lad,
  },
  MediaCampaign: {
    path: '/campanha-de-midia',
    exact: true,
    component: MediaCampaign,
  },
  MediaCampaignId: {
    path: '/campanha-de-midia/:id',
    exact: true,
    component: Campaign,
  },
  QuizzesMain: {
    path: '/treinamentos',
    exact: true,
    component: QuizzesMain,
  },
  QuizzesTraining: {
    path: '/treinamentos/detalhes',
    exact: true,
    component: QuizzesTraining,
  },
};

export default routes;
