const tier = [
  {
    tier: '1+',
    max_price: 'R$ 9.999',
    points: '25',
  },
  {
    tier: '1',
    max_price: 'R$ 5.399',
    points: '20',
  },
  {
    tier: '2',
    max_price: 'R$ 2.699',
    points: '15',
  },
  {
    tier: '3',
    max_price: 'R$ 1.999',
    points: '10',
  },
  {
    tier: '4',
    max_price: 'R$ 1.499',
    points: '7',
  },
  {
    tier: '5',
    max_price: 'R$ 999',
    points: '4',
  },
  {
    tier: '6',
    max_price: 'R$ 699',
    points: '2',
  },
  {
    tier: '7',
    max_price: 'R$ 499',
    points: '2',
  },
  {
    tier: '8',
    max_price: 'R$ 399',
    points: '2',
  },
];

const produto = [
  {
    product: 'Modem',
    tier: '2',
    points: '10',
  },
  {
    product: 'Roteador',
    tier: '2',
    points: '10',
  },
];
const disp = [
  {
    tier: '1',
    categoria: 'Tablet',
    subcategoria: 'Tablet/Notebook',
    qtPontos: '15',
  },
  {
    tier: '2',
    categoria: 'Smartwatch',
    subcategoria: 'Smartwatch',
    qtPontos: '10',
  },
  {
    tier: '3',
    categoria: 'Mesh',
    subcategoria: 'Mesh',
    qtPontos: '4',
  },
  {
    tier: '3',
    categoria: 'Gamer',
    subcategoria: 'Gamer',
    qtPontos: '4',
  },
  {
    tier: '3',
    categoria: 'Audio',
    subcategoria: 'Caixa de som',
    qtPontos: '4',
  },
  {
    tier: '3',
    categoria: 'Smartspeaker',
    subcategoria: 'Smartspeaker',
    qtPontos: '4',
  },
  {
    tier: '4',
    categoria: 'Audio',
    subcategoria: 'Fones',
    qtPontos: '2',
  },
  {
    tier: '4',
    categoria: 'Energia',
    subcategoria: 'Carregadores',
    qtPontos: '2',
  },
  {
    tier: '4',
    categoria: 'Casa Conectada',
    subcategoria: 'Casa Conectada',
    qtPontos: '2',
  },
  {
    tier: '4',
    categoria: 'Suporte e Proteção',
    subcategoria: 'Capas',
    qtPontos: '2',
  },
  {
    tier: '4',
    categoria: 'Energia',
    subcategoria: 'Cabos',
    qtPontos: '2',
  },
  {
    tier: '4',
    categoria: 'Suporte e Proteção',
    subcategoria: 'Suportes',
    qtPontos: '2',
  },
  {
    tier: '5',
    categoria: 'Peliculas',
    subcategoria: 'Peliculas',
    qtPontos: '2',
  },
];

export const list = [
  {
    name: 'Tier',
    header: ['Tier', 'Preço Máximo Pré Pago', 'Pontuação Aparelho'],
    items: tier,
  },
  {
    name: 'Produto',
    header: ['Produto', 'Tier', 'TotalPontos'],
    items: produto,
  },
  {
    name: '',
    header: ['Tier', 'Categoria', 'Subcategoria', 'Qt.Pontos'],
    items: disp,
  },
];
