import { Form } from '@unform/web';
import { useState } from 'react';
import Select from '~/components/Select';
import Input from '~/components/Input';
import Loading from '~/components/Loading';
import { HTTPErrorCallback } from '~/Helpers/Error';
import api from '~/services/api';
import { showNotification } from '~/utils/notificationBoxes';
import { appendObjectInFormData } from '~/utils';
import * as S from './styles';
import { verifyFile } from '~/Helpers/FileValidator';

const CreateTickets = () => {
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);

  const subject_option = [
    { label: 'Alteração cadastral', value: '2' },
    { label: 'Pontos em validação', value: '3' },
    { label: 'Contestação de vendas', value: '4' },
    { label: 'Acesso Site', value: '5' },
    { label: 'Acesso Catálogo Prêmios', value: '6' },
    { label: 'Resgates de prêmios', value: '7' },
    { label: 'Reset de Senha', value: '8' },
    { label: 'Estorno de pontos', value: '9' },
    { label: 'Outros', value: '1' },
  ];

  const createTicket = (data) => {
    setLoading(true);
    if (data.open_reason === '*') {
      setLoading(false);
      return showNotification(
        'warning',
        'Atenção',
        'Selecione uma opção de razão de abertura!',
      );
    }
    const formData = new FormData();
    appendObjectInFormData(formData, {
      ...data,
    });
    for (const file of files) {
      const item = Object.entries(file)[0];
      formData.append('file', new File([item[1]], item[0]));
    }
    api
      .post('/tickets/create', formData)
      .then((response) => {
        setLoading(false);
        showNotification('success', 'Ticket criado com sucesso!');
        window.location.replace(
          window.location.href.split('/')[0] + '/ocorrencias',
        );
      })
      .catch((error) => {
        setLoading(false);
        return HTTPErrorCallback(error);
      });
  };

  return (
    <>
      {loading && <Loading />}
      <S.Wrapper>
        <S.FormWrapper>
          <Form onSubmit={createTicket}>
            <Select
              name="open_reason"
              label="Razão da abertura"
              options={subject_option}
              defaultValue={{ label: 'Selecione uma opção', value: '*' }}
              required
            />
            <br />
            <Input name="comment" label="Mensagem" textArea required />
            <br />
            <input
              type="file"
              onChange={(e) => verifyFile(e, setFiles)}
              multiple
            />
            <br />
            <br />
            <S.SubmitButton type="submit">Criar Ticket</S.SubmitButton>
          </Form>
        </S.FormWrapper>
      </S.Wrapper>
    </>
  );
};

export default CreateTickets;
