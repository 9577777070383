/* eslint-disable no-console */
import { useEffect } from 'react';

import { goTopPage } from '~/utils';
import { parseJwt } from '~/services/auth';
import Rows from './components/Rows';
import * as S from './styles';
import TemporaryVarejoTable from './components/TemporaryVarejoTable';
import TemporaryAATAble from './components/TemporaryAATable';
import { list } from './components/TemporaryVarejoTable/columns';
import { list as AAList } from './components/TemporaryAATable/columns';
import { list as AparelhosList } from './components/TemporaryAparelhosTable/columns';
import TemporaryAparelhosTable from './components/TemporaryAparelhosTable';
import TemporaryRecargaTable from './components/TemporaryRecargaTable';
import TemporaryPAPTable from './components/TemporaryPAPTable';
import TemporaryPAPPCTable from './components/TemporaryPAPPCTable';
import { list as RecargaList } from './components/TemporaryRecargaTable/columns';
import { list as PapList } from './components/TemporaryPAPTable/columns';
import { list as PapPCList } from './components/TemporaryPAPPCTable/columns';

const TablePoints = () => {
  useEffect(() => {
    goTopPage();
  }, []);
  const channel_id = parseJwt().canal_id;
  const switchTable = () => {
    switch (channel_id) {
      case 2:
        return (
          <>
            {list.map((item) => (
              <TemporaryVarejoTable name={item[0]} items={item[1]} />
            ))}
          </>
        );
      case 4:
        return (
          <>
            {AAList.map((item) => (
              <TemporaryAATAble name={item[0]} items={item[1]} />
            ))}
          </>
        );
      case 6:
        return (
          <>
            {AparelhosList.map((item) => (
              <TemporaryAparelhosTable
                name={item.name}
                header={item.header}
                items={item.items}
              />
            ))}
          </>
        );
      case 5:
        return (
          <>
            {RecargaList.map((item) => (
              <TemporaryRecargaTable
                name={item.name}
                header={item.header}
                items={item.items}
              />
            ))}
          </>
        );
      case 8:
        return (
          <>
            {PapList.map((item) => (
              <TemporaryPAPTable
                name={item.name}
                header={item.header}
                items={item.items}
                notes={item.notes}
                notes2={item.notes2}
                notes3={item.notes3}
              />
            ))}
          </>
        );
      case 9:
        return (
          <>
            {PapPCList.map((item) => (
              <TemporaryPAPPCTable
                name={item.name}
                header={item.header}
                items={item.items}
                notes={item.notes}
              />
            ))}
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <S.Wrapper>
      <S.Title>Tabela de Pontos</S.Title>
      {switchTable()}
    </S.Wrapper>
  );
};

export default TablePoints;
