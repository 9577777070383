import * as S from './styles';

const TemporaryVarejoTable = ({ items, name }) => {
  return (
    <>
      <S.Title>{name}</S.Title>
      <S.Description className="opened">
        {name === 'Supervisor BKO' ? (
          <table>
            <tbody>
              {items.map((item) => {
                return (
                  <tr>
                    <td>{item.elegible}</td>
                    <td>{item.value}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <table>
            <thead>
              <td>Produto</td>
              <td>Pontos</td>
              <td>Fid.</td>
              <td>Port In</td>
              <td>Port. + Fid.</td>
            </thead>
            <tbody>
              {items.map((item) => {
                return (
                  <tr>
                    <td>{item.product}</td>
                    <td>{item.points}</td>
                    <td>{item.fid}</td>
                    <td>{item.portin}</td>
                    <td>{item.portFod}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
        <br />
      </S.Description>
    </>
  );
};

export default TemporaryVarejoTable;
