import React from 'react';
import * as S from './styles';

const TemporaryPAPTable = ({ notes, notes2, notes3, name, header, items }) => {
  const renderTableHeader = () => (
    <thead>
      {header.map((item, index) => (
        <td key={index}>{item}</td>
      ))}
    </thead>
  );

  const renderTableBody = () => (
    <tbody>
      {items.map((item, index) => (
        <tr key={index}>
          {Object.values(item).map((value, index) => (
            <td key={index}>{value}</td>
          ))}
        </tr>
      ))}
    </tbody>
  );

  return (
    <>
      <S.Title>{name}</S.Title>

      <S.Description className="opened">
        <table>
          {renderTableHeader()}
          {renderTableBody()}
        </table>
        <S.Description className="notes">{notes}</S.Description>
        <S.Description className="notes">{notes2}</S.Description>
        <S.Description className="notes">{notes3}</S.Description>
        <br />
      </S.Description>
    </>
  );
};

export default TemporaryPAPTable;
