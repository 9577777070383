import * as S from './styles';

const TemporaryRecargaTable = ({ name, header, items }) => {
  return (
    <>
      <S.Title>{name}</S.Title>
      <S.Description className="opened">
        <table>
          <thead>
            {header.map((item, index) => (
              <td key={index}>{item}</td>
            ))}
          </thead>
          <tbody>
            {items.map((item, index) => {
              return (
                <tr key={index}>
                  {Object.values(item).map((item, index) => (
                    <td key={index}>{item}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
        <br />
      </S.Description>
    </>
  );
};

export default TemporaryRecargaTable;
