const g101 = [
  {
    produto: 'Banda Larga',
    '8a10': '30',
    '11a15': '40',
    '>=16': '50',
  },
  {
    produto: 'Bônus Conversão: >85% Instalação sobre VB ',
    '8a10': '+25% da pontuação ',
    '11a15': '+25% da pontuação ',
    '>=16': '+25% da pontuação ',
  },
];
const g102 = [
  {
    produto: 'TV Tradicional / Claro Box',
    '8a10': '15',
    '11a15': '20',
    '>=16': '25',
  },
  {
    produto: 'Combo Multi (Triple Play+Móvel) ',
    '8a10': '50',
    '11a15': '65',
    '>=16': '85',
  },
  {
    produto: 'Bônus Conversão: >85% Instalação sobre VB ',
    '8a10': '+25% da pontuação ',
    '11a15': '+25% da pontuação ',
    '>=16': '+25% da pontuação ',
  },
];

const g103 = [
  {
    produto: 'Banda Larga',
    '8a10': '10',
    '11a15': '20',
    '>=16': '30',
  },
  {
    produto: 'Bônus Conversão: >85% Instalação sobre VB ',
    '8a10': '+25% da pontuação ',
    '11a15': '+25% da pontuação ',
    '>=16': '+25% da pontuação ',
  },
];
const g104 = [
  {
    produto: 'TV Tradicional / Claro Box',
    '8a10': '5',
    '11a15': '10',
    '>=16': '15',
  },
  {
    produto: 'Combo Multi (Triple Play+Móvel) ',
    '8a10': '20',
    '11a15': '35',
    '>=16': '50',
  },
  {
    produto: 'Bônus Conversão: >85% Instalação sobre VB ',
    '8a10': '+25% da pontuação ',
    '11a15': '+25% da pontuação ',
    '>=16': '+25% da pontuação ',
  },
];
const bko = [
  {
    produto: 'Pontuação sobre Venda Bruta',
    '8a10': '4',
    '11a15': '0',
  },
  {
    produto: 'Pontuação sobre Instalação ',
    '8a10': '2',
    '11a15': '2',
  },
];
const supbko = [
  {
    produto: 'Elegibilidade Instalação sobre VB  - Regional',
    '8a10': '=>75% ',
  },
  {
    produto: 'Elegibilidade Produtividade BKO (mínimo de VBs) ',
    '8a10': '500',
  },
  {
    produto: 'Pontos Bônus por HC',
    '8a10': '400',
  },
  {
    produto: 'Pontuação Máxima',
    '8a10': '3000',
  },
];

export const list = [
  {
    name: `Pontuação G10`,
    header: ['Produtos', 'Prod 8 a 10 ', 'Prod 11 a 15 ', 'Prod >=16'],
    items: g101,
  },
  {
    header: ['Produtos', 'Prod 4 a 8 ', 'Prod 9 a 12 ', 'Prod >=13'],
    items: g102,
  },
  {
    name: `Pontuação G20/ Agências/ Q1/ Q2 `,
    header: ['Produtos', 'Prod 8 a 10 ', 'Prod 11 a 15 ', 'Prod >=16'],
    items: g103,
  },
  {
    header: ['Produtos', 'Prod 4 a 8 ', 'Prod 9 a 12 ', 'Prod >=13'],
    items: g104,
    notes: 'Importante: A meta de elegibilidade é por produto ',
  },
  {
    name: `Atribuição de Pontos dos Backoffices e Supervisores `,
    header: ['Pontuação BKO ', 'Agências ', 'Outras'],
    items: bko,
  },
  {
    header: ['Supervisor BKO', 'Pont.'],
    items: supbko,
    notes:
      'Nota:  As pontuações a serem atribuídas por produtos, bem como o valor em reais (R$) a ser atribuído a cada ponto, são critérios que poderão ser revistos pela Realizadora, a seu exclusivo critério, durante todo o período de participação.  ',
  },
];
