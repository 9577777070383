/* eslint-disable no-console */
import { useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Form } from '@unform/web';

import Input from '~/components/Input';
import Loading from '~/components/Loading';
import Logo from '~/components/Logo';
import api from '~/services/api';
import { showNotification } from '~/utils/notificationBoxes';

import * as S from './styles';
import { HTTPErrorCallback } from '~/Helpers/Error';
import ReCaptcha from '~/components/Global/Recaptcha';
import UseTwoFactor from '~/hooks/UseTwoFactor';

const SignIn = () => {
  const history = useHistory();
  const formRef = useRef(null);
  const [contato, setContato] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loginValue, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [currentFormStep, setCurrentFormStep] = useState('login');
  const [reCaptchaToken, setReCaptchaToken] = useState(null);
  const { openModal, setCallback } = UseTwoFactor();
  const number = process.env.REACT_APP_NUMBER;

  useEffect(() => {
    if (localStorage.getItem('userToken')) return history.push('/dashboard');

    if (localStorage.getItem('adminUserToken'))
      return history.push('/admin/dashboard');

    return api
      .get(`/fale-conosco`)
      .then((response) => setContato(response.data.data));
  }, []);

  const login = async () => {
    const callAPI = async (digits, setOpen, callback) => {
      console.log(digits);
      try {
        setLoading(true);
        const response = await api.post('/admin-login', {
          login: loginValue,
          senha: password,
          recaptcha: reCaptchaToken,
          digits,
        });

        setLoading(false);
        if (response.data.open_two_factor && callback) {
          setCallback(() => callback);
          return openModal();
        } else setOpen(false);
        localStorage.setItem('adminUserToken', response.data.token);
        localStorage.setItem('canalNome', response.data.canal_nome);
        sendVersion(response.data.usu_id);
        window.location.reload();
        return true;
      } catch (error) {
        setCurrentFormStep('login');
        setLoading(false);
        if (error.response?.data?.error?.includes('código')) {
          setCallback(() => callback);
          openModal();
        }
        return HTTPErrorCallback(error);
      }
    };

    await callAPI('', () => {}, callAPI);
  };

  const sendVersion = async (usu_id) => {
    let version = await fetch('/version.txt').then((response) =>
      response.text(),
    );

    if (version.includes('<!DOCTYPE html>')) version = 'No Version';

    api.post('/login-version', {
      usu_id,
      version,
    });
  };

  return (
    <S.Container>
      {loading && <Loading />}
      <S.Background>
        <img src="/images/ciclo-7-login-background.jpg" alt="" />
      </S.Background>
      <S.Content>
        <S.ContainerLogo>
          <Logo origin="signin" />
        </S.ContainerLogo>
        <p>ENTRE COM SUAS CREDENCIAS</p>
        <Form ref={formRef} onSubmit={login}>
          <Input
            name="login"
            placeholder="LOGIN"
            value={loginValue}
            onChange={(e) => {
              setLogin(e.target.value);
            }}
          />
          <Input
            type="password"
            name="password"
            className="form-input"
            placeholder="SENHA"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <ReCaptcha setReCaptchaToken={setReCaptchaToken} />
          <S.Button type="submit">ENTRAR</S.Button>
        </Form>
        <S.Contact>
          <h1>CENTRAL DE ATENDIMENTO</h1>
          <S.Central>
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 24 24"
              width="20px"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path fill="none" d="M0 0h24v24H0z"></path>
              <path d="M19.05 4.91A9.816 9.816 0 0 0 12.04 2c-5.46 0-9.91 4.45-9.91 9.91 0 1.75.46 3.45 1.32 4.95L2.05 22l5.25-1.38c1.45.79 3.08 1.21 4.74 1.21 5.46 0 9.91-4.45 9.91-9.91 0-2.65-1.03-5.14-2.9-7.01zm-7.01 15.24c-1.48 0-2.93-.4-4.2-1.15l-.3-.18-3.12.82.83-3.04-.2-.31a8.264 8.264 0 0 1-1.26-4.38c0-4.54 3.7-8.24 8.24-8.24 2.2 0 4.27.86 5.82 2.42a8.183 8.183 0 0 1 2.41 5.83c.02 4.54-3.68 8.23-8.22 8.23zm4.52-6.16c-.25-.12-1.47-.72-1.69-.81-.23-.08-.39-.12-.56.12-.17.25-.64.81-.78.97-.14.17-.29.19-.54.06-.25-.12-1.05-.39-1.99-1.23-.74-.66-1.23-1.47-1.38-1.72-.14-.25-.02-.38.11-.51.11-.11.25-.29.37-.43s.17-.25.25-.41c.08-.17.04-.31-.02-.43s-.56-1.34-.76-1.84c-.2-.48-.41-.42-.56-.43h-.48c-.17 0-.43.06-.66.31-.22.25-.86.85-.86 2.07 0 1.22.89 2.4 1.01 2.56.12.17 1.75 2.67 4.23 3.74.59.26 1.05.41 1.41.52.59.19 1.13.16 1.56.1.48-.07 1.47-.6 1.67-1.18.21-.58.21-1.07.14-1.18s-.22-.16-.47-.28z"></path>
            </svg>
            <p>
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://api.whatsapp.com/send?phone=551127766205`}
              >
                {process.env.REACT_APP_NUMBER}
              </a>
            </p>
          </S.Central>
        </S.Contact>
      </S.Content>
    </S.Container>
  );
};

export default SignIn;
