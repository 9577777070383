import styled from 'styled-components';
import media from 'styled-media-query';

export const Progress = styled.circle`
  fill: none;
  stroke-width: 10px;
  stroke: url(#linear);
  stroke-linecap: round;
  stroke-dasharray: 326.56;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
`;
export const WishContainer = styled.div`
  .rec.rec-arrow {
    border-radius: 0;
    background-color: transparent;
    border: none;
    box-shadow: none;
    color: #c50101;
  }
  .rec-carousel-item-visible {
    .progress {
      animation: anima 1.5s ease-in-out forwards;
    }
  }

  .rec-item-wrapper {
    min-height: 200px;
  }

  .rec-dot_active {
    box-shadow: 0 0 1px 3px #c50101;
    background-color: #c5010160;
  }
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  gap: 20px;
  width: 100vw;
  overflow-x: hidden;
  ${media.greaterThan('medium')`
width: 100%;
`};
`;

export const Plus = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #c50101;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MoreWish = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-direction: column;
  cursor: pointer;
  p {
    display: flex;
    align-items: center;
    gap: 10px;
  }
`;
export const WishScrollArea = styled.div`
  overflow-x: auto;
  overflow-y: hidden;

  width: 100vw;
  overflow-x: hidden;
  ${media.greaterThan('medium')`
padding: 20px;

width: 100%;
`};
`;
export const Wish = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 20px 20px;
  background-color: #fff;
  border-radius: 10px;
  min-width: 300px;
  max-width: 300px;
  height: max-content;
  min-height: 200px;
  // box-shadow: 0px 0px 20px rgba(133, 133, 133, 0.3);
  .circle-big {
    position: relative;
    width: 114px;
    height: 114px;
    background-size: contain;
    background-repeat: none;
    background-position: center;
    border-radius: 50%;
    overflow: hidden;
  }

  .wish-points {
    font-size: 1.2rem !important;
    color: #c50101;
    font-weight: 700;
    text-align: center;
    margin-top: 10px;
  }
  .wish-observation {
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.2rem;
    margin-top: 20px;
    letter-spacing: 0em;
    text-align: center;
  }

  .circle-big svg {
    width: 114px;
    height: 114px;
  }

  .circle-big .bg {
    fill: none;
    stroke-width: 10px;
    stroke: #faa9a9;
    background-color: black;
  }

  @keyframes anima {
    from {
      stroke-dashoffset: 326.56;
    }
    to {
      stroke-dashoffset: ${({ progress }) => progress};
    }
  }
`;
export const Wrapper = styled.section`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(133, 133, 133, 0.3);

  padding: 70px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  overflow-x: hidden;
  ${media.greaterThan('medium')`
  width: 100%;
  `};
`;

export const WishWrapper = styled.section`
  background-color: #fff;
  border-radius: 10px;
  // box-shadow: 0px 0px 20px rgba(133, 133, 133, 0.3);
  padding: 17px 15px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  h2 {
    font-size: 1.6rem;
    color: #c50101;
    font-weight: 500;
    text-align: left;
  }
  width: 100vw;
  overflow-x: hidden;
  ${media.greaterThan('medium')`
width: 100%;
`};
`;

export const WishTitle = styled.h3`
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  margin: 20px 0px;
`;
export const WishesTitle = styled.h2`
  width: 100%;
  text-align: left;
`;
