const movel = [
  {
    product: 'Pré Pago c/ Inserção D+7',
    points: '10',
    fid: '-',
    portin: '15',
    portFod: '-',
  },
  {
    product: 'Controle Boleto',
    points: '50',
    fid: '55',
    portin: '60',
    portFod: '65',
  },
  {
    product: 'Controle Fácil 15GB',
    points: '50',
    fid: '-',
    portin: '60',
    portFod: '-',
  },
  {
    product: 'Controle Fácil 20GB',
    points: '60',
    fid: '-',
    portin: '70',
    portFod: '-',
  },
  {
    product: 'Pós Pago',
    points: '50',
    fid: '55',
    portin: '60',
    portFod: '65',
  },
];

const fixo = [
  {
    product: 'TV',
    points: '-',
    fid: '50',
    portin: '-',
    portFod: '-',
  },
  {
    product: 'Banda Larga',
    points: '-',
    fid: '80',
    portin: '-',
    portFod: '-',
  },
  {
    product: 'Fone',
    points: '-',
    fid: '5',
    portin: '-',
    portFod: '-',
  },
  {
    product: 'Combo Multi (Triple Play + Móvel)',
    points: '-',
    fid: '150',
    portin: '-',
    portFod: '-',
  },
  {
    product: 'Claro Box',
    points: '50',
    fid: '-',
    portin: '-',
    portFod: '-',
  },
  {
    product: 'App',
    points: '10',
    fid: '-',
    portin: '-',
    portFod: '-',
  },
];

const bko = [
  {
    product: 'Pontuação sobre Venda Bruta',
    points: '10',
    fid: '-',
    portin: '-',
    portFod: '-',
  },
  {
    product: 'Pontuação sobre instalação',
    points: '5',
    fid: '-',
    portin: '-',
    portFod: '-',
  },
];

const bko_supervisor = [
  {
    elegible: 'Elegibilidade VB sobre Proposta - Regional',
    value: '=> 80%',
  },
  {
    elegible: 'Elegibilidade Produtividade BKO(mínimo de VBs)',
    value: '80',
  },
  {
    elegible: 'Pontos Bônus por HC',
    value: '500',
  },
  {
    elegible: 'Pontuação Máxima',
    value: '4.000',
  },
];

export const list = [
  ['Móvel', movel],
  ['Fixo', fixo],
  ['Pontuação BKO', bko],
  ['Supervisor BKO', bko_supervisor],
];
