import * as S from './styles';

const TemporaryAATAble = ({ items, name }) => {
  return (
    <>
      <S.Title>{name}</S.Title>
      <S.Description className="opened">
        <table>
          <thead>
            <td>Produto</td>
            <td>GB</td>
            <td>Pontos</td>
            <td>Portabilidade</td>
            <td>Pontos + Portabilidade</td>
          </thead>
          <tbody>
            {items.map((items) => (
              <tr>
                <td>{items.product}</td>
                <td>{items.gb}</td>
                <td>{items.points}</td>
                <td>{items.port}</td>
                <td>{items.points_port_debit}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <br />
      </S.Description>
    </>
  );
};

export default TemporaryAATAble;
