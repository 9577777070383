import React from 'react';
import { useHistory } from 'react-router-dom';
import SidebarButton from '~components/NexAdmin/Buttons/SidebarButton';
import { parseJwt } from '~/services/auth';
import adminRoutes from '~/routes/Components/NexAdmin/routes';
import Logo from '~/components/Logo';
import { restrictAccess, allowAccess } from '~/Helpers/RestrictAccess';
import UseAuthentication from '~/hooks/NexAdmin/AuthenticationHook';

import * as S from './styles';

const SideBar = ({ opened, hoverOpened, setHoverOpened }) => {
  const history = useHistory();
  const { parseJwt } = UseAuthentication();

  const logout = () => {
    localStorage.removeItem('adminUserToken');
    localStorage.removeItem('userToken');
    localStorage.removeItem('canalNome');
    window.location.href = '/admin';
  };

  const checkIndividualChannelAndLevel = (channel, level) => {
    return (
      channel.includes(parseJwt.canal_id) &&
      level.some((nivel) => parseJwt.usu_nivel > nivel)
    );
  };

  const navItems = [
    {
      icon: 'dashboard',
      label: 'Dashboard',
      active: window.location.pathname === adminRoutes.Home.path,
      onClick: () => history.push(adminRoutes.Home.path),
      show: allowAccess({ level: [50] }),
    },
    {
      icon: 'filedUser',
      label: 'Cadastros',
      active: window.location.pathname === adminRoutes.UserRegistration.path,
      onClick: () => history.push(adminRoutes.UserRegistration.path),
      show: true,
      subItems: [
        {
          label: 'Inserir Usuários',
          onClick: () => history.push(adminRoutes.UserRegistrationCreate.path),
          show:
            [8, 9].includes(parseJwt.canal_id) &&
            (parseJwt.usu_nivel >= 45 ||
              parseJwt.usu_nome?.toLowerCase().includes('claro')),
          activeDropdown:
            window.location.pathname ===
            adminRoutes.UserRegistrationCreate.path,
        },
        {
          label: 'Histórico',
          onClick: () => history.push(adminRoutes.UserRegistrationList.path),
          show:
            [8, 9].includes(parseJwt.canal_id) &&
            (parseJwt.usu_nivel >= 50 ||
              parseJwt.usu_nivel === 45 ||
              parseJwt.usu_nome?.toLowerCase().includes('claro')),
          activeDropdown:
            window.location.pathname === adminRoutes.UserRegistrationList.path,
        },
        {
          label: 'Bloqueio de Usuários',
          onClick: () => history.push(adminRoutes.BlockUsers.path),
          show:
            [2, 8, 9].includes(parseJwt.canal_id) &&
            parseJwt.usu_nivel !== 47 &&
            // (parseJwt.usu_nivel >= 50 ||
            //   parseJwt.usu_nome?.toLowerCase().includes('claro')),
            parseJwt.usu_nivel >= 1100,
          activeDropdown:
            window.location.pathname === adminRoutes.BlockUsers.path,
        },
        {
          label: 'Histórico de Bloqueio',
          onClick: () => history.push(adminRoutes.BlockUsersList.path),
          show:
            [2, 8, 9].includes(parseJwt.canal_id) &&
            parseJwt.usu_nivel !== 47 &&
            // (parseJwt.usu_nivel >= 50 ||
            //   parseJwt.usu_nome?.toLowerCase().includes('claro')),
            parseJwt.usu_nivel >= 1100,
          activeDropdown:
            window.location.pathname === adminRoutes.BlockUsersList.path,
        },
      ],
    },
    {
      icon: 'actions',
      label: 'Vendas',
      active: window.location.pathname === adminRoutes.Sales.path,
      onClick: () => history.push(adminRoutes.Sales.path),
      show:
        parseJwt.canal_id === 6
          ? allowAccess({ level: [3] })
          : parseJwt.canal_id === 9
          ? allowAccess({ level: [43] })
          : true,
      subItems: [
        {
          label: 'Inserir Vendas',
          onClick: () => history.push(adminRoutes.CreateSale.path),
          show:
            [8].includes(parseJwt.canal_id) &&
            (parseJwt.usu_nivel >= 50 ||
              parseJwt.usu_nivel === 45 ||
              parseJwt.usu_nome?.toLowerCase().includes('claro')),
          activeDropdown:
            window.location.pathname === adminRoutes.CreateSale.path,
        },
        {
          label: 'Histórico',
          onClick: () => history.push(adminRoutes.SalesHistory.path),
          show:
            [8].includes(parseJwt.canal_id) &&
            (parseJwt.usu_nivel >= 50 ||
              parseJwt.usu_nivel === 45 ||
              parseJwt.usu_nome?.toLowerCase().includes('claro')),
          activeDropdown:
            window.location.pathname === adminRoutes.SalesHistory.path,
        },
      ],
    },
    {
      icon: 'actions',
      label: 'Validação de Vendas',
      active: window.location.pathname === adminRoutes.SalesValidator.path,
      onClick: () => history.push(adminRoutes.SalesValidator.path),
      show:
        [2, 4].includes(parseJwt.canal_id) &&
        parseJwt.usu_nivel !== 47 &&
        (parseJwt.usu_nivel >= 45 ||
          parseJwt.usu_nome?.toLowerCase().includes('claro')),
      subItems: [
        {
          label: 'Histórico',
          onClick: () => history.push(adminRoutes.SalesValidatorList.path),
          show:
            [2, 4].includes(parseJwt.canal_id) &&
            parseJwt.usu_nivel !== 47 &&
            (parseJwt.usu_nivel >= 50 ||
              parseJwt.usu_nome?.toLowerCase().includes('claro')),
          activeDropdown:
            window.location.pathname === adminRoutes.SalesValidatorList.path,
        },
      ],
    },
    // {
    //   icon: 'actions',
    //   label: 'Bloqueio de Usuários',
    //   active: window.location.pathname === adminRoutes.BlockUsers.path,
    //   onClick: () => history.push(adminRoutes.BlockUsers.path),
    //   show:
    //     [2, 4].includes(parseJwt.canal_id) &&
    //     parseJwt.usu_nivel !== 47 &&
    //     (parseJwt.usu_nivel >= 45 ||
    //       parseJwt.usu_nome?.toLowerCase().includes('claro')),
    //   subItems: [
    //     {
    //       label: 'Histórico',
    //       onClick: () => history.push(adminRoutes.SalesValidatorList.path),
    //       show:
    //         [2, 4].includes(parseJwt.canal_id) &&
    //         parseJwt.usu_nivel !== 47 &&
    //         (parseJwt.usu_nivel >= 50 ||
    //           parseJwt.usu_nome?.toLowerCase().includes('claro')),
    //       activeDropdown:
    //         window.location.pathname === adminRoutes.SalesValidatorList.path,
    //     },
    //   ],
    // },
    {
      icon: 'statusSketch',
      label: 'Extrato',
      active: window.location.pathname === adminRoutes.ExtractReport.path,
      onClick: () => history.push(adminRoutes.ExtractReport.path),
      show:
        parseJwt.canal_id === 5
          ? allowAccess({ level: [3] }) || allowAccess({ claro_user: true })
          : parseJwt.canal_id === 6
          ? allowAccess({ level: [1] })
          : parseJwt.canal_id === 2 || parseJwt.canal_id === 4
          ? parseJwt.usu_nivel !== 44
          : parseJwt.canal_id === 9 || parseJwt.canal_id === 8
          ? parseJwt.usu_nivel !== 44
          : true,
    },
    {
      icon: 'statusArchived',
      label: 'Pedidos',
      active: window.location.pathname === adminRoutes.OrdersReport.path,
      onClick: () => history.push(adminRoutes.OrdersReport.path),
      show: true,
      subItems: [
        {
          label: 'Bloqueados Premmiar',
          onClick: () => history.push(adminRoutes.BlockedOrdersReport.path),
          show: allowAccess({ level: [50] }),
          activeDropdown:
            window.location.pathname === adminRoutes.BlockedOrdersReport.path,
        },
      ],
    },
    {
      icon: 'magnifier',
      label: 'Validar CPF',
      active: window.location.pathname === adminRoutes.ValidateCpf.path,
      onClick: () => history.push(adminRoutes.ValidateCpf.path),
      show: parseJwt.usu_nivel !== 47 && allowAccess({ level: [45] }),
    },
    {
      icon: 'newProject',
      label: parseJwt.canal_id == 9 ? 'Pontos Vendas' : 'Pontos Bonus',
      active: window.location.pathname === adminRoutes.BonusPointReport.path,
      onClick: () => history.push(adminRoutes.BonusPointReport.path),
      show:
        parseJwt.usu_nivel === 47
          ? false
          : [8, 9].includes(parseJwt.canal_id) &&
            [44, 43].includes(parseJwt.usu_nivel)
          ? false
          : parseJwt.canal_id == 5
          ? parseJwt.usu_nivel !== 45 && parseJwt.usu_nivel !== 47
          : parseJwt.canal_id == 2
          ? parseJwt.usu_nivel !== 43 && parseJwt.usu_nivel !== 44
          : allowAccess({ level: [50] }) ||
            checkIndividualChannelAndLevel([2, 8, 9], [3]),
      subItems: [
        {
          label: 'Criar',
          onClick: () => history.push(adminRoutes.CreateBonusPoints.path),
          show:
            allowAccess({ level: [50] }) ||
            checkIndividualChannelAndLevel([2, 8, 9], [3]),
          activeDropdown:
            window.location.pathname === adminRoutes.CreateBonusPoints.path,
        },
      ],
    },
    {
      icon: 'posts',
      label: 'Gigaline',
      active: window.location.pathname === adminRoutes.CreatePosts.path,
      onClick: () => history.push(adminRoutes.CreatePosts.path),
      show: allowAccess({ level: [50] }),
      subItems: [
        {
          label: 'Criar Post',
          onClick: () => history.push(adminRoutes.CreatePosts.path),
          show: allowAccess({ level: [50] }),
          activeDropdown:
            window.location.pathname === adminRoutes.CreatePosts.path,
        },
        {
          label: 'Moderar',
          onClick: () => history.push(adminRoutes.ListPosts.path),
          show: allowAccess({ level: [50] }),
          activeDropdown:
            window.location.pathname === adminRoutes.ListPosts.path,
        },
      ],
    },
    {
      icon: 'send',
      label: 'Trocar E-mails',
      active: window.location.pathname === adminRoutes.ChangeBulkEmail.path,
      onClick: () => history.push(adminRoutes.ChangeBulkEmail.path),
      show: allowAccess({ level: [50] }),
    },
    {
      icon: 'duplicate',
      label: 'Landing Pages',
      active: window.location.pathname === adminRoutes.LandingPages.path,
      onClick: () => history.push(adminRoutes.LandingPages.path),
      show:
        checkIndividualChannelAndLevel([5], [1]) &&
        allowAccess({ level: [50] }),
      subItems: [
        {
          label: 'Criar',
          onClick: () => history.push(adminRoutes.CreateLandingPages.path),
          show:
            checkIndividualChannelAndLevel([5], [1]) &&
            allowAccess({ level: [50] }),
          activeDropdown:
            window.location.pathname === adminRoutes.CreateLandingPages.path,
        },
      ],
    },
    {
      icon: 'projects',
      label: 'Rock Dash',
      active: window.location.pathname === adminRoutes.RockDash.path,
      onClick: () => history.push(adminRoutes.RockDash.path),
      show: allowAccess({ level: [50] }),
    },
    {
      icon: 'upload',
      label: 'Banner',
      active: window.location.pathname === adminRoutes.Banners.path,
      onClick: () => history.push(adminRoutes.Banners.path),
      show: allowAccess({ level: [50] }),
      subItems: [
        {
          label: 'Criar',
          onClick: () => history.push(adminRoutes.CreateBanners.path),
          show: allowAccess({ level: [50] }),
          activeDropdown:
            window.location.pathname === adminRoutes.CreateBanners.path,
        },
      ],
    },
    // {
    //   icon: 'user',
    //   label: 'Receita',
    //   active: window.location.pathname === adminRoutes.Revenue.path,
    //   onClick: () => history.push(adminRoutes.Revenue.path),
    //   show: parseJwt.canal_id === 8 && allowAccess({ level: [50] }),
    // },
    // {
    //   icon: 'newGuest',
    //   label: 'Inserir usuários',
    //   active: window.location.pathname === adminRoutes.PAPCreateUsers.path,
    //   onClick: () => history.push(adminRoutes.PAPCreateUsers.path),
    //   show: parseJwt.canal_id === 8 && allowAccess({ level: [50] }),
    // },
    {
      icon: 'monitor',
      label: 'Ocorrências',
      active: window.location.pathname === adminRoutes.ListTickets.path,
      onClick: () => history.push(adminRoutes.ListTickets.path),
      show: allowAccess({ level: [50] }),
      subItems: [
        {
          label: 'Criar',
          onClick: () => history.push(adminRoutes.CreateTickets.path),
          activeDropdown:
            window.location.pathname === adminRoutes.CreateTickets.path,
          show: allowAccess({ level: [50] }),
        },
        {
          label: 'Relatório',
          onClick: () => history.push(adminRoutes.ReportTickets.path),
          activeDropdown:
            window.location.pathname === adminRoutes.ReportTickets.path,
          show: allowAccess({ level: [50] }),
        },
      ],
    },
    {
      icon: 'notification',
      label: 'Notificações',
      active: window.location.pathname === adminRoutes.ListPush.path,
      onClick: () => history.push(adminRoutes.ListPush.path),
      show: allowAccess({ level: [50] }),
      subItems: [
        {
          label: 'Criar',
          onClick: () => history.push(adminRoutes.Push.path),
          activeDropdown: window.location.pathname === adminRoutes.Push.path,
          show: allowAccess({ level: [50] }),
        },
      ],
    },
    {
      icon: 'projects',
      label: 'Desafios',
      active: window.location.pathname === adminRoutes.ListChallenges.path,
      onClick: () => history.push(adminRoutes.ListChallenges.path),
      show: parseJwt.canal_id === 7 && allowAccess({ level: [50] }),
      subItems: [
        {
          label: 'Criar Desafios',
          onClick: () => history.push(adminRoutes.CreateChallenge.path),
          show: parseJwt.canal_id === 7 && allowAccess({ level: [50] }),
          activeDropdown:
            window.location.pathname === adminRoutes.CreateChallenge.path,
        },
      ],
    },
    {
      icon: 'newGuest',
      label: 'Indicações',
      active: window.location.pathname === adminRoutes.Indications.path,
      onClick: () => history.push(adminRoutes.Indications.path),
      show: restrictAccess({ channel: [7, 8] }) && allowAccess({ level: [50] }),
    },
    {
      icon: 'config',
      label: parseJwt.canal_id == 5 ? 'Giga GN' : 'GN',
      active: window.location.pathname === adminRoutes.GNGeral.path,
      onClick: () => history.push(adminRoutes.GNGeral.path),
      show:
        (parseJwt.usu_nivel === 47 && parseJwt.canal_id === 2) ||
        ([2, 4, 5].includes(parseJwt.canal_id) && allowAccess({ level: [3] })),
      subItems: [
        {
          label: 'Campanhas',
          onClick: () => history.push(adminRoutes.GNCampagns.path),
          show:
            [2, 4, 5].includes(parseJwt.canal_id) &&
            (allowAccess({ level: [48] }) || allowAccess({ claro_user: true })),
          activeDropdown:
            window.location.pathname === adminRoutes.GNCampagns.path,
        },
        {
          label: 'Inserir Pontos',
          onClick: () => history.push(adminRoutes.GNPoints.path),
          show:
            [2, 4, 5].includes(parseJwt.canal_id) &&
            (allowAccess({ level: [48] }) || allowAccess({ claro_user: true })),
          activeDropdown:
            window.location.pathname === adminRoutes.GNPoints.path,
        },
        {
          label: 'Inserir Dados em Massa',
          onClick: () => history.push(adminRoutes.GNBatches.path),
          show:
            [2, 4, 5].includes(parseJwt.canal_id) &&
            (allowAccess({ level: [48] }) || allowAccess({ claro_user: true })),
          activeDropdown:
            window.location.pathname === adminRoutes.GNBatches.path,
        },
      ],
    },

    {
      icon: 'config',
      label: 'LAD',
      active: window.location.pathname === adminRoutes.LADGeral.path,
      onClick: () => history.push(adminRoutes.LADGeral.path),
      show:
        parseJwt.canal_id == 5 ||
        parseJwt.canal_id == 2 ||
        parseJwt.canal_id == 4
          ? ![48, 47, 46, 45, 44, 43].includes(parseJwt.usu_nivel)
          : (parseJwt.canal_id == 2 || parseJwt.canal_id == 4) &&
            allowAccess({ level: [4] }),
      subItems: [
        {
          label: 'Campanhas',
          onClick: () => history.push(adminRoutes.LADCampagns.path),
          show:
            (parseJwt.canal_id == 2 || parseJwt.canal_id == 4) &&
            (allowAccess({ level: [50] }) || allowAccess({ claro_user: true })),
          activeDropdown:
            window.location.pathname === adminRoutes.LADCampagns.path,
        },
        {
          label: 'Inserir Pontos',
          onClick: () => history.push(adminRoutes.LADPoints.path),
          show:
            (parseJwt.canal_id == 2 || parseJwt.canal_id == 4) &&
            (allowAccess({ level: [50] }) || allowAccess({ claro_user: true })),
          activeDropdown:
            window.location.pathname === adminRoutes.LADPoints.path,
        },
        {
          label: 'Inserir Dados em Massa',
          onClick: () => history.push(adminRoutes.LADBatches.path),
          show:
            (parseJwt.canal_id == 2 || parseJwt.canal_id == 4) &&
            (allowAccess({ level: [50] }) || allowAccess({ claro_user: true })),
          activeDropdown:
            window.location.pathname === adminRoutes.LADBatches.path,
        },
      ],
    },
    {
      icon: 'add',
      label: 'Pop-Up',
      active: window.location.pathname === adminRoutes.ListPopUp.path,
      onClick: () => history.push(adminRoutes.ListPopUp.path),
      show: allowAccess({ level: [50] }),
      subItems: [
        {
          label: 'Criar',
          onClick: () => history.push(adminRoutes.CreatePopUp.path),
          show: allowAccess({ level: [50] }),
          activeDropdown:
            window.location.pathname === adminRoutes.CreatePopUp.path,
        },
      ],
    },
    {
      icon: 'newGuest',
      label: 'Carrossel de Campanhas',
      active:
        window.location.pathname === adminRoutes.CampaignsExhibitionList.path,
      onClick: () => history.push(adminRoutes.CampaignsExhibitionList.path),
      show: allowAccess({ level: [50] }),
      subItems: [
        {
          label: 'Criar',
          onClick: () => history.push(adminRoutes.CampaignsExhibition.path),
          show: true,
          activeDropdown:
            window.location.pathname === adminRoutes.CampaignsExhibition.path,
        },
      ],
    },
    {
      icon: 'newGuest',
      label: 'Campanhas de Foto e Vídeo',
      active: window.location.pathname === adminRoutes.MediaCampaignsList.path,
      onClick: () => history.push(adminRoutes.MediaCampaignsList.path),
      show:
        allowAccess({ level: [50] }) ||
        (parseJwt.canal_id == 5 && allowAccess({ claro_user: true })),
      subItems: [
        {
          label: 'Criar',
          onClick: () => history.push(adminRoutes.MediaCampaigns.path),
          show: true,
          activeDropdown:
            window.location.pathname === adminRoutes.MediaCampaigns.path,
        },
        {
          label: 'Auditoria',
          onClick: () => history.push(adminRoutes.AuditMedia.path),
          show: true,
          activeDropdown:
            window.location.pathname === adminRoutes.AuditMedia.path,
        },
      ],
    },
    {
      icon: 'chartBar',
      label: 'Módulo de Treinamento',
      active: window.location.pathname === adminRoutes.ListQuiz.path,
      onClick: () => history.push(adminRoutes.ListQuiz.path),
      show:
        parseJwt.canal_id == 9
          ? false
          : parseJwt.canal_id == 8 ||
            parseJwt.canal_id == 5 ||
            parseJwt.canal_id == 6 ||
            parseJwt.canal_id == 2 ||
            parseJwt.canal_id == 4
          ? parseJwt.usu_nivel >= 50
          : allowAccess({ level: [4] }),
      subItems: [
        {
          label: 'Criar',
          onClick: () => history.push(adminRoutes.CreateQuiz.path),
          show: allowAccess({ level: [4] }),
          activeDropdown:
            window.location.pathname === adminRoutes.CreateQuiz.path,
        },
      ],
    },
    {
      icon: 'database',
      label: 'Migrações',
      active: window.location.pathname === adminRoutes.MigrationReport.path,
      onClick: () => history.push(adminRoutes.MigrationReport.path),
      show: allowAccess({ level: [50] }),
    },
    {
      icon: 'access',
      label: 'Redes',
      active: window.location.pathname === adminRoutes.Networks.path,
      onClick: () => history.push(adminRoutes.Networks.path),
      show:
        parseJwt.canal_id == 2
          ? allowAccess({ level: [48] })
          : parseJwt.canal_id == 4
          ? allowAccess({ level: [50] }) || parseJwt.usu_nivel === 45
          : false,
      subItems: [
        {
          label: 'Criar Rede',
          onClick: () => history.push(adminRoutes.NetworksCreate.path),
          show: true,
          activeDropdown:
            window.location.pathname === adminRoutes.NetworksCreate.path,
        },
        {
          label: 'Upload em Massa',
          onClick: () => history.push(adminRoutes.NetworksMassUpload.path),
          show: true,
          activeDropdown:
            window.location.pathname === adminRoutes.NetworksMassUpload.path,
        },
      ],
    },
  ];
  return (
    <S.Wrapper
      onMouseOver={() => setHoverOpened(true)}
      onMouseLeave={() => setHoverOpened(false)}
      hoverOpened={hoverOpened}
      opened={opened}
    >
      <S.Header hoverOpened={hoverOpened} opened={opened}>
        <Logo origin="header" />
        <S.Credentials hoverOpened={hoverOpened} opened={opened}>
          <S.Name title={parseJwt.usu_nome}>{parseJwt.usu_nome}</S.Name>
          <S.Email title={parseJwt.email}>{parseJwt.email}</S.Email>
        </S.Credentials>
      </S.Header>
      <S.Navbar hoverOpened={hoverOpened} opened={opened}>
        {navItems.map(
          (navItem) =>
            navItem.show && (
              <SidebarButton
                key={navItem.label}
                subItems={
                  navItem.subItems &&
                  navItem.subItems.filter((subItem) => subItem.show).length >
                    0 &&
                  navItem.subItems.filter((subItem) => subItem.show)
                }
                active={navItem.active}
                activeDropdown={navItem.activeDropdown}
                icon={navItem.icon}
                label={navItem.label}
                onClick={navItem.onClick}
                opened={opened}
                hoverOpened={hoverOpened}
              />
            ),
        )}
      </S.Navbar>
      <SidebarButton
        onClick={() => logout()}
        icon="logout"
        label="Sair"
        hoverOpened={hoverOpened}
        opened={opened}
      />
      <div className="version">v.2.0</div>
    </S.Wrapper>
  );
};

export default SideBar;
