import styled from 'styled-components';

export const Description = styled.div`
  padding: 28px 0px 18px;

  table {
    width: 100%;
    text-align: center;
    color: ${({ theme }) => theme.colors.grayDarker};

    thead {
      font-weight: 700;
      font-size: 1.6rem;
    }

    td {
      border: 1px solid gray;
      border-radius: 5px;
    }

    .notes {
      text-align: left;
      display: flex;
      max-width: 100%;
    }
  }
`;

export const Title = styled.h1`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.red};
  text-align: left;
`;
