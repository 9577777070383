const movel = [
  {
    product: 'Controle',
    gb: 'Todos',
    points: '8',
    port: '2',
    points_port_debit: '10',
  },
  {
    product: 'Pós Pago',
    gb: '25',
    points: '26',
    port: '4',
    points_port_debit: '30',
  },
  {
    product: 'Pós Pago',
    gb: '50',
    points: '100',
    port: '15',
    points_port_debit: '115',
  },
  {
    product: 'Pós Pago',
    gb: '75',
    points: '250',
    port: '70',
    points_port_debit: '320',
  },
  {
    product: 'Pós Pago',
    gb: '150',
    points: '300',
    port: '100',
    points_port_debit: '400',
  },
];

const fixo = [
  {
    product: 'Banda Larga',
    gb: '20',
    points: '5',
    debit: '-',
    port: '-',
    points_port_debit: '5',
  },
  {
    product: 'Banda Larga',
    gb: '40',
    points: '5',
    debit: '-',
    port: '-',
    points_port_debit: '5',
  },
  {
    product: 'Banda Larga',
    gb: '120',
    points: '5',
    debit: '-',
    port: '-',
    points_port_debit: '5',
  },
];

const dependente = [
  {
    product: 'Dependente',
    gb: '-',
    points: '3',
    port: '2',
    points_port_debit: '5',
  },
];
export const list = [
  ['Móvel', movel],
  // ['Fixo', fixo],
  ['Dependente', dependente],
];
