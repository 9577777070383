const role = [
  {
    role: 'PDC RED',
    profile: 'Estrutural',
    inova: 'PDC RED',
    detail: 'Estrutural RED',
    has_red: 'Sim',
    chip_flex: 'Elegível',
    chip_combo: 'Elegível',
    sd: 'Não Elegível',
    hd: 'Não Elegível',
    decoder: 'Não Elegível',
  },
  {
    role: 'RED',
    profile: 'Estrutural',
    inova: 'RED',
    detail: 'Estrutural RED',
    has_red: 'Sim',
    chip_flex: 'Elegível',
    chip_combo: 'Elegível',
    sd: 'Não Elegível',
    hd: 'Não Elegível',
    decoder: 'Não Elegível',
  },
  {
    role: 'SUPERVISOR RED',
    profile: 'Estrutural',
    inova: 'SUPERVISOR_RED',
    detail: 'Estrutural RED',
    has_red: 'Sim',
    chip_flex: 'Elegível',
    chip_combo: 'Elegível',
    sd: 'Não Elegível',
    hd: 'Não Elegível',
    decoder: 'Não Elegível',
  },
  {
    role: 'VENDEDOR PDC',
    profile: 'Estrutural',
    inova: 'VENDEDOR_PDC',
    detail: 'Estrutural RED',
    has_red: 'Sim',
    chip_flex: 'Elegível',
    chip_combo: 'Elegível',
    sd: 'Não Elegível',
    hd: 'Não Elegível',
    decoder: 'Não Elegível',
  },
  {
    role: 'PDC MAR ABERTO',
    profile: 'Estrutural',
    inova: 'PDC MAR ABERTO',
    detail: 'Estrutural Mar Aberto',
    has_red: 'Não',
    chip_flex: 'Elegível',
    chip_combo: 'Elegível',
    sd: 'Não Elegível',
    hd: 'Não Elegível',
    decoder: 'Não Elegível',
  },
  {
    role: 'VENDEDOR DB',
    profile: 'Estrutural',
    inova: 'VENDEDOR_DB',
    detail: 'Estrutural Mar Aberto',
    has_red: 'Não',
    chip_flex: 'Elegível',
    chip_combo: 'Elegível',
    sd: 'Não Elegível',
    hd: 'Não Elegível',
    decoder: 'Não Elegível',
  },
  {
    role: 'VENDEDOR PDC',
    profile: 'Estrutural',
    inova: 'VENDEDOR_PDC',
    detail: 'Estrutural Mar Aberto',
    has_red: 'Não',
    chip_flex: 'Elegível',
    chip_combo: 'Elegível',
    sd: 'Não Elegível',
    hd: 'Não Elegível',
    decoder: 'Não Elegível',
  },
  {
    role: 'PDC ESP',
    profile: 'Especialista',
    inova: 'PDC ESP',
    detail: 'Especialista',
    has_red: 'Não',
    chip_flex: 'Não Elegível',
    chip_combo: 'Não Elegível',
    sd: 'Elegível',
    hd: 'Elegível',
    decoder: 'Elegível',
  },
  {
    role: 'VENDEDOR DB ESP',
    profile: 'Especialista',
    inova: 'VENDEDOR DB ESP',
    detail: 'Especialista',
    has_red: 'Não',
    chip_flex: 'Não Elegível',
    chip_combo: 'Não Elegível',
    sd: 'Elegível',
    hd: 'Elegível',
    decoder: 'Elegível',
  },
  {
    role: 'GERENTE DB ESP',
    profile: 'Especialista',
    inova: 'GERENTE DB ESP',
    detail: 'Especialista',
    has_red: 'Não',
    chip_flex: 'Não Elegível',
    chip_combo: 'Não Elegível',
    sd: 'Elegível',
    hd: 'Elegível',
    decoder: 'Elegível',
  },
];

const points = [
  {
    role: 'PDC RED',
    profile: 'Estrutural',
    inova: 'PDC RED',
    detail: 'Estrutural RED',
    has_red: 'Sim',
    has_pdc: 'Não',
    percentagem: '100',
    chip_flex: '20',
    chip_combo: '2',
    sd: '-',
    hd: '-',
    decoder: '-',
  },
  {
    role: 'PDC RED',
    profile: 'Estrutural',
    inova: 'PDC RED',
    detail: 'Estrutural RED',
    has_red: 'Sim',
    has_pdc: 'Sim',
    percentagem: '10',
    chip_flex: '2',
    chip_combo: '0,2',
    sd: '-',
    hd: '-',
    decoder: '',
  },
  {
    role: 'RED',
    profile: 'Estrutural',
    inova: 'RED',
    detail: 'Estrutural RED',
    has_red: 'Sim',
    has_pdc: '-',
    percentagem: '5',
    chip_flex: '1',
    chip_combo: '0,1',
    sd: '-',
    hd: '-',
    decoder: '',
  },
  {
    role: 'SUPERVISOR RED',
    profile: 'Estrutural',
    inova: 'SUPERVISOR_RED',
    detail: 'Estrutural RED',
    has_red: 'Sim',
    has_pdc: '-',
    percentagem: '3',
    chip_flex: '0,6',
    chip_combo: '0,1',
    sd: '-',
    hd: '-',
    decoder: '-',
  },
  {
    role: 'VENDEDOR PDC',
    profile: 'Estrutural',
    inova: 'VENDEDOR_PDC',
    detail: 'Estrutural RED',
    has_red: 'Sim',
    has_pdc: '-',
    percentagem: '100',
    chip_flex: '20',
    chip_combo: '2',
    sd: '-',
    hd: '-',
    decoder: '-',
  },
  {
    role: 'PDC MAR ABERTO',
    profile: 'Estrutural',
    inova: 'PDC MAR ABERTO',
    detail: 'Estutural Mar Aberto',
    has_red: 'Não',
    has_pdc: 'Não',
    percentagem: '100',
    chip_flex: '30',
    chip_combo: '3',
    sd: '-',
    hd: '-',
    decoder: '-',
  },
  {
    role: 'PDC MAR ABERTO',
    profile: 'Estrutural',
    inova: 'PDC MAR ABERTO',
    detail: 'Estutural Mar Aberto',
    has_red: 'Não',
    has_pdc: 'Sim',
    percentagem: '10',
    chip_flex: '3',
    chip_combo: '0,3',
    sd: '-',
    hd: '-',
    decoder: '-',
  },
  {
    role: 'VENDEDOR DB',
    profile: 'Estrutural',
    inova: 'VENDEDOR DE',
    detail: 'Estutural Mar Aberto',
    has_red: 'Não',
    has_pdc: '-',
    percentagem: '10',
    chip_flex: '3',
    chip_combo: '0,3',
    sd: '-',
    hd: '-',
    decoder: '-',
  },
  {
    role: 'VENDEDOR PDC',
    profile: 'Estrutural',
    inova: 'VENDEDOR PDC',
    detail: 'Estutural Mar Aberto',
    has_red: 'Não',
    has_pdc: '-',
    percentagem: '100',
    chip_flex: '30',
    chip_combo: '3',
    sd: '-',
    hd: '-',
    decoder: '-',
  },
  {
    role: 'PDC ESP',
    profile: 'Especialista',
    inova: 'PDC ESP',
    detail: 'Especialista',
    has_red: 'Não',
    has_pdc: 'Sim',
    percentagem: '100',
    chip_flex: '-',
    chip_combo: '-',
    sd: '10',
    hd: '10',
    decoder: '2',
  },
  {
    role: 'VENDEDOR DB ESP',
    profile: 'Especialista',
    inova: 'VENDEDOR DE ESP',
    detail: 'Especialista',
    has_red: 'Não',
    has_pdc: '-',
    percentagem: '10',
    chip_flex: '-',
    chip_combo: '-',
    sd: '1',
    hd: '1',
    decoder: '0,2',
  },
  {
    role: 'GERENTE DB ESP',
    profile: 'Especialista',
    inova: 'GERENTE DB ESP',
    detail: 'Especialista',
    has_red: 'Não',
    has_pdc: '-',
    percentagem: '3',
    chip_flex: '-',
    chip_combo: '-',
    sd: '0,3',
    hd: '0,3',
    decoder: '0,1',
  },
];

export const list = [
  {
    name: 'Elegibilidade por Cargo',
    header: [
      'Cargo',
      'Perfil',
      'Nomenclatura Inova Autenticação',
      'Perfil Detalhe',
      'Tem RED?',
      'Ativação Chip Flex',
      'Ativação Chip Combo',
      'Ativação Decoder Claro TV Pre SD',
      'Ativação Decoder Claro TV Pre HD',
      'Rastreamento Decoder',
    ],
    items: role,
  },
  {
    name: 'Pontuação',
    header: [
      'Cargo',
      'Perfil',
      'Nomenclatura Inova Autenticação',
      'Perfil Detalhe',
      'Tem RED?',
      'Tem Vendedor PDC?',
      'Percentual Ponto por Cargo(válido para todos os produtos)',
      'Ativação Chip Flex',
      'Ativação Chip Combo',
      'Ativação Decoder Claro TV Pre SD',
      'Ativação Decoder Claro TV Pre HD',
      'Rastreamento Decoder',
    ],
    items: points,
  },
];
