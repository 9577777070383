const premium = [
  {
    faixa: 'Meta Elegibilidade',
    first: '15',
    second: '20',
    third: '22',
  },
  {
    faixa: 'Pont. por Venda',
    first: '80',
    second: '90',
    third: '100',
  },
  {
    faixa: 'Premiação(mínimo vendas)',
    first: '1200',
    second: '1.800',
    third: '2.200',
  },
];
const premium2 = [
  {
    faixa: 'Meta Elegibilidade',
    first: '8',
    second: '10',
    third: '15',
  },
  {
    faixa: 'Pont. por Venda',
    first: '80',
    second: '90',
    third: '100',
  },
  {
    faixa: 'Premiação(mínimo vendas)',
    first: '640',
    second: '900',
    third: '1500',
  },
];
//pontuacao_premium
const pontuacao_premium = [
  {
    faixa: 'Pontuação por venda',
    first: '20',
  },
];
const pontuacao_premium2 = [
  {
    faixa: 'Total de Pontos por assistente que atingir',
    first: '100',
  },
];
const pontuacao_premium3 = [
  {
    first:
      'Pontuação: 10% sobre a pontuação recebida pelos assistentes de sua carteira',
  },
];
//pontuacao_premium
//pontuacao_premium_bko
const pontuacao_premium_bko = [
  {
    faixa: 'Pontuação por venda',
    first: '3',
  },
];
const pontuacao_premium_bko2 = [
  {
    faixa: 'Total de Pontos por assistente (se a regional bater a meta)',
    first: '10',
  },
];
const pontuacao_premium_bko3 = [
  {
    first:
      'Pontuação: 10% sobre a pontuação recebida pelos assistentes de sua carteira',
  },
];
//pontuacao_premium_bko
//pontuacao_condiminio_bko
const pontuacao_condiminio_bko = [
  {
    faixa: 'Pontuação por venda',
    first: '3',
  },
];
const pontuacao_condiminio_bko2 = [
  {
    faixa: 'Total de Pontos por assistente(se o canal bater a meta)',
    first: '10',
  },
];
const pontuacao_condiminio_bko3 = [
  {
    first:
      'Pontuação: 10% sobre a pontuação recebida pelos assistentes de sua carteira',
  },
];
//pontuacao_premium_bko
const coordenador_premium = [
  {
    header: 'Elegibilidade: mínimo de 50% da equipe elegível',
    ponto: '10%',
  },
];

const supervisor_premium = [
  {
    info: 'Elegibilidade: não se aplica',
  },
];

const supervisor_condominio = [
  {
    info: 'Elegibilidade: mínimo de 50% da equipe elegível',
    ponto: '20%',
  },
];

const coordenador_condominio = [
  {
    info: 'Elegibilidade: mínimo de 50% da equipe elegível',
    ponto: '10%',
  },
];

export const list = [
  {
    name: 'Canal Premium',
    header: ['Faixa', '1', '2', '3'],
    items: premium,
  },
  {
    header: ['Supervisor'],
    items: supervisor_premium,
  },
  {
    header: ['Coordenador ', 'Pontuação'],
    items: coordenador_premium,
  },
  //Pontuação Assistentes - PAP Premium
  {
    name: 'Pontuação Assistentes - PAP Premium',
    header: [
      'Conversão de BL igual ou maior do que 90% (instalação sobre Venda Bruta)',
      '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0',
    ],
    items: pontuacao_premium,
  },
  {
    header: [
      'Atingimento igual ou maior do que 90% da meta de BL da Regional',
      '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0',
    ],
    items: pontuacao_premium2,
  },
  {
    header: ['Coordenador Assistentes'],
    items: pontuacao_premium3,
  },
  //Pontuação Assistentes - PAP Premium
  //Pontuação Assistentes Backoffice - PAP Premium
  {
    name: 'Pontuação Backoffices e Supervisores - PAP Premium',
    header: [
      'Conversão de BL igual ou maior do que 90% (instalação sobre Venda Bruta)',
      '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0',
    ],
    items: pontuacao_premium_bko,
    notes:
      'Nota: O total de pontos da regional será dividido igualmente para todos os assistentes ativos no mês',
  },
  {
    header: [
      'Atingimento igual ou maior do que 100% da meta de BL da Regional',
      '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0',
    ],
    items: pontuacao_premium_bko2,
  },
  {
    header: ['Coordenador Assistentes'],
    items: pontuacao_premium_bko3,
    notes2:
      'As pontuações a serem atribuidas por produtos, bem como o valor em reais (R$) a ser atribuido a cada ponto, são critérios que poderão ser revistos pela Realizadora, a seu exclusivo critério, durante todo o periodo de participação.',
  },

  //Pontuação Assistentes Backoffice - PAP Premium

  {
    name: 'Canal Condomínio',
    header: ['Faixa', '1', '2', '3'],
    items: premium2,
  },
  {
    name: 'Supervisor - Canal Condomínio',
    header: [' ', 'Pontuação'],
    items: supervisor_condominio,
  },
  {
    name: 'Coordenador - Canal Condomínio',
    header: [' ', 'Pontuação'],
    items: coordenador_condominio,
  },
  //Pontuação Assistentes Backoffice - PAP Condomínio
  {
    name: 'Pontuação Backoffices e Supervisores - PAP Condomínio',
    header: [
      'Conversão de BL igual ou maior do que 90% (instalação sobre Venda Bruta)',
      '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0',
    ],
    items: pontuacao_condiminio_bko,
    notes:
      'Nota: O total de pontos do canal será dividido igualmente para todos os assistentes ativos no mês',
  },
  {
    header: [
      'Atingimento igual ou maior do que 100% da meta de BL do Canal',
      '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0',
    ],
    items: pontuacao_condiminio_bko2,
  },
  {
    header: ['Coordenador e Supervisor Assistentes'],
    items: pontuacao_condiminio_bko3,

    notes2:
      'As pontuações a serem atribuidas por produtos, bem como o valor em reais (R$) a ser atribuido a cada ponto, são critérios que poderão ser revistos pela Realizadora, a seu exclusivo critério, durante todo o periodo de participação.',
  },
  //Pontuação Assistentes Backoffice - PAP Condomínio
];
