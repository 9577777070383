import styled, { keyframes } from 'styled-components';
import media from 'styled-media-query';

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: stretch;
  overflow: hidden;
  .password.passwordActive,
  .forgot.forgotActive {
    display: flex !important;
    align-items: center;
    flex-direction: column;
    height: 100%;
    justify-content: center;
  }
  .forgot.forgotActive {
    display: flex !important;
    flex-direction: column;
    height: 100%;
    justify-content: center;
  }
  .forgot-form-input {
    // margin-bottom: 20px;
  }
  .forgot .input {
    margin-bottom: 20px;
  }
`;
export const Forgot = styled.div`
  width: 100%;
  text-decoration: underline;
  text-align: center;
  font-size: 12px;
  padding-top: 5px;
  cursor: pointer;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  overflow-y: auto;
  max-width: 700px;
  background-color: #fff;
  animation: ${appearFromRight} 1.5s;
  gap: 5px;
  p {
    color: #7e7e7e;
  }
  form {
    /* padding: 30px; */
    display: flex;
    flex-direction: column;
    align-self: center;

    height: 100%;
    gap: 5px;
    width: 70%;

    label {
      width: 100%;
    }

    div {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`;

export const Column = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column !important;
  gap: 20px;
`;
export const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: row !important;
  gap: 20px;
`;
export const Background = styled.div`
  width: 100%;
  height: 100vh;
  flex: 1;
  background-color: #fff;

  img {
    width: 100%;
    height: 100%;
    /* object-fit: cover;
    object-position: 0 -2px; */
  }
`;

export const Buttons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const Contact = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: #ededed; */
  color: #7e7e7e;
  align-self: center;
  padding: 14px;
  border-top: 1px solid;
  ${media.greaterThan('medium')`
    padding: 24px;
  `}

  > h1 {
    font-size: 1.6rem;
    font-family: ${({ theme }) => theme.font.ff_roboto};
    margin-bottom: 15px;

    ${media.greaterThan('medium')`
      margin-bottom: 35px;
    `}
  }

  ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    align-items: baseline;

    li {
      & + li {
        margin-bottom: 20px;
        padding: 10px;
      }

      h1 {
        text-transform: uppercase;
        font-size: 1.1rem;
        font-family: ${({ theme }) => theme.font.ff_roboto};
      }

      p {
        font-size: 1.1rem;
        font-weight: 300;
        font-family: ${({ theme }) => theme.font.ff_roboto};

        &:first-of-type {
          margin: 4px 0;
        }
      }
    }
  }
`;

export const ContainerLogo = styled.div`
  width: 209px;
  height: 202px;
  align-self: center;
  margin: 30px;
`;

export const Button = styled.button`
  background: ${({ theme }) => theme.colors.degrade};
  border-radius: 25px;
  border: 0;
  padding: 10px 30px;
  display: flex;
  align-items: center;
  min-width: 120px;
  justify-content: center;
  color: #fff;
  font-weight: 400;
  font-size: 1.4rem;
  transition: box-shadow 0.2s;
  text-decoration: none;
  cursor: pointer;
  margin-top: 20px;

  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`;
export const Central = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  a {
    text-decoration: none;
    color: inherit;
  }
  svg {
    width: 25px;
    margin-right: 5px;
  }

  p {
    font-size: 1.6rem;
    font-family: ${({ theme }) => theme.font.ff_roboto};
    margin: unset;

    ${media.greaterThan('medium')`
      margin-right: 10px;
    `}
  }
`;
