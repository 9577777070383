import styled from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.section`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(133, 133, 133, 0.3);
  margin-top: 30px;
  padding: 20px 10px;
  width: 100vw;
  max-width: 100vw;
  overflow: hidden;
  ${media.greaterThan('medium')`
  padding: 70px 50px;
  width: inherit;
  position: relative;
`};
`;

export const Title = styled.p`
  top: 20px;
  left: 25px;
  font-size: ${({ theme }) => theme.font.sizes.xsmall};
  color: ${({ theme }) => theme.colors.red};
  font-weight: ${({ theme }) => theme.font.medium};
  position: absolute;
  font-weight: 700;
`;

export const Message = styled.h1``;
