import React, { useEffect, useState } from 'react';
import VerificationCode from '~/components/TwoFactorModal';
import api from '~/services/api';
import UseAuthentication from '~/hooks/NexAdmin/AuthenticationHook';
import { HTTPErrorCallback } from '~/Helpers/Error';
import Loading from '~/components/Loading';

const FEATURES = {
  'common-login': 'common-login',
  'common-premmiar-sso': 'common-premmiar-sso',
  'admin-bonus-points': 'admin-bonus-points',
  'admin-gn-sell-validation': 'admin-gn-sell-validation',
  'admin-training-creation': 'admin-training-creation',
};

export const TwoFactorContext = React.createContext({
  requestCode: (type, callback = (digits, setOpen) => {}) => {},
  requirements: '*',
  twoFactorIsRequired: (type) => {},
  FEATURES,
  openModal: () => {},
  setCallback: () => {},
  loading: false,
  setCodeLoading: () => {},
});

const TwoFactorProvider = ({ children }) => {
  const { parseJwt } = UseAuthentication();
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setCodeLoading] = useState(false);
  const [requirements, setRequirements] = useState('*');
  const [callback, setCallback] = useState(() => {});

  useEffect(() => {
    if (!Object.keys(parseJwt).length) return;

    setCodeLoading(true);
    api
      .get(`/two-factor/requirements/${parseJwt.canal_id}`)
      .then((response) => {
        setRequirements(response.data?.requirements);
        setCodeLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setCodeLoading(false);
      });

    //eslint-disable-next-line
  }, [parseJwt]);

  const requestCode = (type, callback = (digits, setOpen) => {}) => {
    if (!twoFactorIsRequired(type)) return callback('', () => {});

    const url =
      parseJwt.type === 'admin' ? '/two-factor/admin' : '/two-factor/common';

    setCodeLoading(true);
    api
      .get(`${url}/${type}`)
      .then((response) => {
        setCodeLoading(false);
        setCallback(() => callback);
        setModalOpen(true);
      })
      .catch((error) => {
        setCodeLoading(false);
        setCallback(() => callback);
        setModalOpen(true);
        HTTPErrorCallback(error);
      });
  };

  const handleSubmit = (data) => {
    const formattedDigits = data.digits
      ? data.digits.replace(/\D/g, '').slice(0, 4)
      : '';
    callback(formattedDigits, setModalOpen);
  };

  const twoFactorIsRequired = (type) => {
    console.log(type);
    if (requirements === '*') return true;

    return requirements.includes(type);
  };

  const contextValue = {
    requestCode,
    requirements,
    twoFactorIsRequired,
    FEATURES,
    openModal: () => setModalOpen(true),
    setCallback,
    loading,
    setCodeLoading,
  };

  return (
    <>
      <TwoFactorContext.Provider value={contextValue}>
        {loading && <Loading />}
        {modalOpen && (
          <VerificationCode
            loading={loading}
            close={() => setModalOpen(false)}
            submit={handleSubmit}
          />
        )}
        {children}
      </TwoFactorContext.Provider>
    </>
  );
};

export default TwoFactorProvider;
