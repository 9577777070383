export const model_data = [
  {
    G20: 'NÃO',
    REGIONAL: 'RSC',
    'RAZAO SOCIAL': 'CLARO S/A',
    CNPJ: '40.432.544/0001-47',
    NOME: 'NATALIA MARTINES',
    CPF: '111.111.111-11',
    'FUNCAO(CLASSIFICACAO)': 'EXECUTIVO',
    SEGMENTO: 'PAP AGENCIAS',
    'EQUIPE DE VENDAS': 'COM_CONDOMINIO_ALTOVALOR_RPO_BCC',
  },
  {
    G20: 'SIM',
    REGIONAL: 'RSI',
    'RAZAO SOCIAL': 'BRASILCENTER COMUNICACOES LTDA',
    CNPJ: '02.917.443/0016-53',
    NOME: 'RENAN EUGENIO DIAS',
    CPF: '222.222.222-22',
    'FUNCAO(CLASSIFICACAO)': 'VENDEDOR',
    SEGMENTO: 'PAP AGENCIAS',
    'EQUIPE DE VENDAS': 'COM_CONDOMINIO_ALTOVALOR_RPO_BCC',
  },
  {
    G20: 'NÃO',
    REGIONAL: 'RSI',
    'RAZAO SOCIAL': 'BRASILCENTER COMUNICACOES LTDA',
    CNPJ: '02.917.443/0016-53',
    NOME: 'FABIO LUIZ ANELI DOS SANTOS',
    CPF: '333.333.333-33',
    'FUNCAO(CLASSIFICACAO)': 'SUPERVISOR',
    SEGMENTO: 'PAP INDIRETO',
    'EQUIPE DE VENDAS': 'COM_CONDOMINIO_ALTOVALOR_RPO_BCC',
  },
];

export const model_data_pc = [
  {
    G20: 'NÃO',
    REGIONAL: 'RSC',
    'RAZAO SOCIAL': 'CLARO S/A',
    CNPJ: '40.432.544/0001-47',
    NOME: 'NATALIA MARTINES',
    CPF: '111.111.111-11',
    'ID USUARIO': 142342,
    'FUNCAO(CLASSIFICACAO)': 'EXECUTIVO',
    SEGMENTO: 'PREMIUM',
    'EQUIPE DE VENDAS': 'COM_CONDOMINIO_ALTOVALOR_RPO_BCC',
  },
  {
    G20: 'SIM',
    REGIONAL: 'RSI',
    'RAZAO SOCIAL': 'BRASILCENTER COMUNICACOES LTDA',
    CNPJ: '02.917.443/0016-53',
    NOME: 'RENAN EUGENIO DIAS',
    CPF: '222.222.222-22',
    'ID USUARIO': 13536,
    'FUNCAO(CLASSIFICACAO)': 'VENDEDOR',
    SEGMENTO: 'CONDOMÍNIOS',
    'EQUIPE DE VENDAS': 'COM_CONDOMINIO_ALTOVALOR_RPO_BCC',
  },
  {
    G20: 'NÃO',
    REGIONAL: 'RSI',
    'RAZAO SOCIAL': 'BRASILCENTER COMUNICACOES LTDA',
    CNPJ: '02.917.443/0016-53',
    NOME: 'FABIO LUIZ ANELI DOS SANTOS',
    CPF: '333.333.333-33',
    'ID USUARIO': 165687,
    'FUNCAO(CLASSIFICACAO)': 'SUPERVISOR',
    SEGMENTO: 'CONDOMÍNIOS',
    'EQUIPE DE VENDAS': 'COM_CONDOMINIO_ALTOVALOR_RPO_BCC',
  },
];

export const model_data_sales = [
  {
    name: 'sale_id',
    selector: 'sale_id',
    cell: (row) => <div>{row['sale_id']}</div>,
  },
  {
    name: 'status',
    selector: 'status',
    cell: (row) => <div>{row['status']}</div>,
  },
  {
    name: 'observation',
    selector: 'observation',
    cell: (row) => <div>{row['observation']}</div>,
  },
];

export const model_columns = [
  { name: 'G20', selector: 'G20', cell: (row) => <div>{row['G20']}</div> },
  {
    name: 'REGIONAL',
    selector: 'REGIONAL',
    cell: (row) => <div>{row['REGIONAL']}</div>,
  },
  { name: 'CNPJ', selector: 'CNPJ', cell: (row) => <div>{row['CNPJ']}</div> },
  {
    name: 'RAZAO SOCIAL',
    selector: 'RAZAO SOCIAL',
    cell: (row) => <div>{row['RAZAO SOCIAL']}</div>,
  },
  { name: 'NOME', selector: 'NOME', cell: (row) => <div>{row['NOME']}</div> },
  { name: 'CPF', selector: 'CPF', cell: (row) => <div>{row['CPF']}</div> },
  {
    name: 'FUNCAO(CLASSIFICACAO)',
    selector: 'FUNCAO(CLASSIFICACAO)',
    cell: (row) => <div>{row['FUNCAO(CLASSIFICACAO)']}</div>,
  },
  {
    name: 'SEGMENTO',
    selector: 'SEGMENTO',
    cell: (row) => <div>{row['SEGMENTO']}</div>,
  },
  {
    name: 'EQUIPE DE VENDAS',
    selector: 'EQUIPE DE VENDAS',
    cell: (row) => <div>{row['EQUIPE DE VENDAS']}</div>,
  },
];

export const model_columns_pc = [
  { name: 'G20', selector: 'G20', cell: (row) => <div>{row['G20']}</div> },
  {
    name: 'REGIONAL',
    selector: 'REGIONAL',
    cell: (row) => <div>{row['REGIONAL']}</div>,
  },
  { name: 'CNPJ', selector: 'CNPJ', cell: (row) => <div>{row['CNPJ']}</div> },
  {
    name: 'RAZAO SOCIAL',
    selector: 'RAZAO SOCIAL',
    cell: (row) => <div>{row['RAZAO SOCIAL']}</div>,
  },
  { name: 'NOME', selector: 'NOME', cell: (row) => <div>{row['NOME']}</div> },
  { name: 'CPF', selector: 'CPF', cell: (row) => <div>{row['CPF']}</div> },
  {
    name: 'ID USUARIO CLARO',
    selector: 'ID USUARIO',
    cell: (row) => <div>{row['ID USUARIO']}</div>,
  },
  {
    name: 'FUNCAO(CLASSIFICACAO)',
    selector: 'FUNCAO(CLASSIFICACAO)',
    cell: (row) => <div>{row['FUNCAO(CLASSIFICACAO)']}</div>,
  },
  {
    name: 'SEGMENTO',
    selector: 'SEGMENTO',
    cell: (row) => <div>{row['SEGMENTO']}</div>,
  },
  {
    name: 'EQUIPE DE VENDAS',
    selector: 'EQUIPE DE VENDAS',
    cell: (row) => <div>{row['EQUIPE DE VENDAS']}</div>,
  },
];

export const model_columns_sales = [
  {
    name: 'SERVIÇO ID',
    selector: (row) => row['sale_id'],
    cell: (row) => <div>{row['sale_id']}</div>,
  },
  {
    name: 'VALIDADO',
    selector: (row) => row['status'],
    cell: (row) => <div>{row['status']}</div>,
  },
  {
    name: 'OBSERVAÇÃO',
    selector: (row) => row['observation'],
    cell: (row) => <div>{row['observation']}</div>,
  },
];

export const model_columns_download = [
  {
    name: 'G20',
    selector: (row) => row['G20'],
    cell: (row) => <div>{row['G20']}</div>,
  },
  {
    name: 'REGIONAL',
    selector: (row) => row['REGIONAL'],
    cell: (row) => <div>{row['REGIONAL']}</div>,
  },
  {
    name: 'CNPJ',
    selector: (row) => row['CNPJ'],
    cell: (row) => <div>{row['CNPJ']}</div>,
  },
  {
    name: 'RAZAO SOCIAL',
    selector: (row) => row['RAZAO SOCIAL'],
    cell: (row) => <div>{row['RAZAO SOCIAL']}</div>,
  },
  {
    name: 'NOME',
    selector: (row) => row['NOME'],
    cell: (row) => <div>{row['NOME']}</div>,
  },
  {
    name: 'CPF',
    selector: (row) => row['CPF'],
    cell: (row) => <div>{row['CPF']}</div>,
  },
  {
    name: 'FUNCAO(CLASSIFICACAO)',
    selector: (row) => row['FUNCAO(CLASSIFICACAO)'],
    cell: (row) => <div>{row['FUNCAO(CLASSIFICACAO)']}</div>,
  },
  {
    name: 'SEGMENTO',
    selector: (row) => row['SEGMENTO'],
    cell: (row) => <div>{row['SEGMENTO']}</div>,
  },
  {
    name: 'EQUIPE DE VENDAS',
    selector: (row) => row['EQUIPE DE VENDAS'],
    cell: (row) => <div>{row['EQUIPE DE VENDAS']}</div>,
  },
];

export const model_columns_download_pc = [
  {
    name: 'G20',
    selector: (row) => row['G20'],
    cell: (row) => <div>{row['G20']}</div>,
  },
  {
    name: 'REGIONAL',
    selector: (row) => row['REGIONAL'],
    cell: (row) => <div>{row['REGIONAL']}</div>,
  },
  {
    name: 'CNPJ',
    selector: (row) => row['CNPJ'],
    cell: (row) => <div>{row['CNPJ']}</div>,
  },
  {
    name: 'RAZAO SOCIAL',
    selector: (row) => row['RAZAO SOCIAL'],
    cell: (row) => <div>{row['RAZAO SOCIAL']}</div>,
  },
  {
    name: 'NOME',
    selector: (row) => row['NOME'],
    cell: (row) => <div>{row['NOME']}</div>,
  },
  {
    name: 'CPF',
    selector: (row) => row['CPF'],
    cell: (row) => <div>{row['CPF']}</div>,
  },
  {
    name: 'ID USUARIO CLARO',
    selector: (row) => row['ID USUARIO'],
    cell: (row) => <div>{row['ID USUARIO']}</div>,
  },
  {
    name: 'FUNCAO(CLASSIFICACAO)',
    selector: (row) => row['FUNCAO(CLASSIFICACAO)'],
    cell: (row) => <div>{row['FUNCAO(CLASSIFICACAO)']}</div>,
  },
  {
    name: 'SEGMENTO',
    selector: (row) => row['SEGMENTO'],
    cell: (row) => <div>{row['SEGMENTO']}</div>,
  },
  {
    name: 'EQUIPE DE VENDAS',
    selector: (row) => row['EQUIPE DE VENDAS'],
    cell: (row) => <div>{row['EQUIPE DE VENDAS']}</div>,
  },
];

export const model_columns_download_sales = [
  {
    name: 'SERVIÇO ID',
    selector: (row) => row['SERVIÇO ID'],
    cell: (row) => <div>{row['SERVIÇO ID']}</div>,
  },
  {
    name: 'VALIDADO',
    selector: (row) => row['VALIDADO'],
    cell: (row) => <div>{row['VALIDADO']}</div>,
  },
  {
    name: 'OBSERVAÇÃO',
    selector: (row) => row['OBSERVAÇÃO'],
    cell: (row) => <div>{row['OBSERVAÇÃO']}</div>,
  },
];
