import ContainerWithTitle from '~/components/NexAdmin/ContainerWithTitle';
import StatusTag from '~/components/NexAdmin/StatusTag';
import {
  ContentInner,
  ContentWrapper,
  HeaderWrapper,
} from '~/styles/Layouts/NexAdmin/wrappers';
import DashDescription from '~/components/NexAdmin/Modal/Descriptions/dashDescription';
import { useModal } from '~/hooks/NexAdmin/MoldalHook';
import { parseJwt } from '../../../services/auth';

const RockDash = () => {
  const user = parseJwt();

  const papDash = [
    {
      regional: '*',
      src: 'https://app.powerbi.com/view?r=eyJrIjoiZjRkZjg2N2EtZDBiMy00ZTM2LTg2NjMtMWJkNzIzODQ2MDBiIiwidCI6IjIzOTIyOTUwLTk2Y2YtNDBmNi04OWI0LTI0OGZjMzQ5OWUyNiJ9',
    },
    {
      regional: 'RBS',
      src: 'https://app.powerbi.com/view?r=eyJrIjoiZDM0M2FlNzQtOGFhMy00OGRkLTlmYTEtNjU4NjVkNTY4MTljIiwidCI6IjIzOTIyOTUwLTk2Y2YtNDBmNi04OWI0LTI0OGZjMzQ5OWUyNiJ9',
    },
    {
      regional: 'RCO',
      src: 'https://app.powerbi.com/view?r=eyJrIjoiMzMxYTZlNTEtMjJmNC00ZDkyLTg4NWMtY2UxY2NjZDU1ZGYxIiwidCI6IjIzOTIyOTUwLTk2Y2YtNDBmNi04OWI0LTI0OGZjMzQ5OWUyNiJ9',
    },
    {
      regional: 'RMG',
      src: 'https://app.powerbi.com/view?r=eyJrIjoiMjYzZDcwYmMtOGJlNC00MjRkLTg3MDUtMjZjYmJlMDJhYjljIiwidCI6IjIzOTIyOTUwLTk2Y2YtNDBmNi04OWI0LTI0OGZjMzQ5OWUyNiJ9',
    },
    {
      regional: 'RNE',
      src: 'https://app.powerbi.com/view?r=eyJrIjoiYzZiM2RlMjItM2QwZC00OWM2LTllZDUtZTA4MzRhYTYzYWQ5IiwidCI6IjIzOTIyOTUwLTk2Y2YtNDBmNi04OWI0LTI0OGZjMzQ5OWUyNiJ9',
    },
    {
      regional: 'RNO',
      src: 'https://app.powerbi.com/view?r=eyJrIjoiMWQzMGJlYzktODc3Zi00ZjBmLTg3YzktZmZmM2I0ZjQxOTU2IiwidCI6IjIzOTIyOTUwLTk2Y2YtNDBmNi04OWI0LTI0OGZjMzQ5OWUyNiJ9',
    },
    {
      regional: 'RPS',
      src: 'https://app.powerbi.com/view?r=eyJrIjoiZjJkODcwNzYtYzk1My00NGVmLThiOTUtM2JmNjk3YWEwN2Q0IiwidCI6IjIzOTIyOTUwLTk2Y2YtNDBmNi04OWI0LTI0OGZjMzQ5OWUyNiJ9',
    },
    {
      regional: 'RRE',
      src: 'https://app.powerbi.com/view?r=eyJrIjoiNWI5NzZiMDYtNTNhYS00Y2VmLWIyZjctN2E2NDc5ODBlNmFiIiwidCI6IjIzOTIyOTUwLTk2Y2YtNDBmNi04OWI0LTI0OGZjMzQ5OWUyNiJ9',
    },
    {
      regional: 'RRS',
      src: 'https://app.powerbi.com/view?r=eyJrIjoiNDY3M2FiNDctYThlZC00ZWQ4LTk5MWMtZmY1NzRmZjhlMDQ1IiwidCI6IjIzOTIyOTUwLTk2Y2YtNDBmNi04OWI0LTI0OGZjMzQ5OWUyNiJ9',
    },
    {
      regional: 'RSC',
      src: 'https://app.powerbi.com/view?r=eyJrIjoiMTUzOTYxMjctN2Y0Ni00ZTZkLWFiODctMGMxNmQ1Zjk4YmFkIiwidCI6IjIzOTIyOTUwLTk2Y2YtNDBmNi04OWI0LTI0OGZjMzQ5OWUyNiJ9',
    },
    {
      regional: 'RSI',
      src: 'https://app.powerbi.com/view?r=eyJrIjoiYWFjNGVkNzMtY2IwZS00NmUxLWE0ZDUtZjAzZDQwNjMwMTkyIiwidCI6IjIzOTIyOTUwLTk2Y2YtNDBmNi04OWI0LTI0OGZjMzQ5OWUyNiJ9',
    },
  ];

  const getRegionalPAP = () => {
    const find =
      user.usu_nivel < 50
        ? papDash.find((item) => item.regional === user.usu_regional)
        : papDash.find((item) => item.regional === '*');
    return find.src;
  };
  const dashes = {
    2: 'https://app.powerbi.com/reportEmbed?reportId=57e31cca-1933-479f-a245-906d0e01ae82&autoAuth=true&ctid=23922950-96cf-40f6-89b4-248fc3499e26&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLWJyYXppbC1zb3V0aC1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D',
    4: 'https://app.powerbi.com/reportEmbed?reportId=b706f638-67b3-47bf-804b-002bf1d23941&autoAuth=true&ctid=23922950-96cf-40f6-89b4-248fc3499e26&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLWJyYXppbC1zb3V0aC1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D',
    6: 'https://app.powerbi.com/reportEmbed?reportId=958cfc93-720b-4dd5-82cb-1fbce83daa09&autoAuth=true&ctid=23922950-96cf-40f6-89b4-248fc3499e26&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLWJyYXppbC1zb3V0aC1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D',
    5: 'https://app.powerbi.com/reportEmbed?reportId=bb2eb1c1-9c7c-4863-b0d3-b70a76c9e7e6&autoAuth=true&ctid=23922950-96cf-40f6-89b4-248fc3499e26&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLWJyYXppbC1zb3V0aC1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D',
    7: 'https://app.powerbi.com/reportEmbed?reportId=6ceff5d6-0c1a-4e7c-a69a-5dd13564ff6f&autoAuth=true&ctid=23922950-96cf-40f6-89b4-248fc3499e26&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLWJyYXppbC1zb3V0aC1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D',
    8: getRegionalPAP(),
  };

  const { openModal } = useModal();
  const openThermsModal = () => {
    openModal({
      title: 'Rock Dash',
      close: true,
      content: <DashDescription />,
    });
  };

  return (
    <>
      <HeaderWrapper>
        <StatusTag
          icon="projects"
          title="Rock Dash"
          modalFunction={openThermsModal}
        />
      </HeaderWrapper>
      <ContentWrapper>
        <ContentInner withDetails>
          <ContainerWithTitle>
            <iframe
              title="Dashboard"
              width="100%"
              height="542"
              src={dashes[user.canal_id]}
              framebBorder="0"
              allowFullScreen="true"
            />
          </ContainerWithTitle>
        </ContentInner>
      </ContentWrapper>
    </>
  );
};

export default RockDash;
